import React, { useEffect, useMemo, useState } from 'react';

// utility
import { api, endpoints } from '../../api';

// components
import Collapse from '@mui/material/Collapse';
import { Box, Card, CardContent, Stack, Typography, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { host } from '../../host';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { VulnerabilityChipCheck } from 'utilities/vulnerabilityAndSignatureCheck';
import { CVE_FIXEDIN_PAGE_SIZE } from 'utilities/paginationConstants';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: '#FFFFFF',
    boxShadow: '0rem 0.3125rem 0.625rem rgba(131, 131, 131, 0.08)',
    border: '1px solid #E0E5EB',
    borderRadius: '0.75rem',
    flex: 'none',
    alignSelf: 'stretch',
    width: '100%',
    marginTop: '2rem',
    marginBottom: '2rem'
  },
  content: {
    textAlign: 'left',
    color: '#606060',
    padding: '2% 3% 2% 3%',
    width: '100%'
  },
  cveId: {
    color: theme.palette.primary.main,
    fontSize: '1rem',
    fontWeight: 400,
    textDecoration: 'underline'
  },
  cveSummary: {
    color: theme.palette.secondary.dark,
    fontSize: '0.75rem',
    fontWeight: '600',
    textOverflow: 'ellipsis',
    marginTop: '0.5rem'
  },
  link: {
    color: '#52637A',
    fontSize: '1rem',
    letterSpacing: '0.009375rem',
    paddingRight: '1rem',
    textDecorationLine: 'underline'
  },
  dropdown: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  dropdownText: {
    color: '#1479FF',
    fontSize: '0.75rem',
    fontWeight: '600',
    cursor: 'pointer',
    textAlign: 'center'
  },
  dropdownCVE: {
    color: '#1479FF',
    cursor: 'pointer'
  },
  vulnerabilityCardDivider: {
    margin: '1rem 0'
  }
}));
function VulnerabilitiyCard(props) {
  const classes = useStyles();
  const { cve, name, platform, expand } = props;
  const [openCVE, setOpenCVE] = useState(expand);
  const [openDesc, setOpenDesc] = useState(false);
  const [openFixed, setOpenFixed] = useState(false);
  const [loadingFixed, setLoadingFixed] = useState(true);
  const [fixedInfo, setFixedInfo] = useState([]);
  const abortController = useMemo(() => new AbortController(), []);

  // pagination props
  const [pageNumber, setPageNumber] = useState(1);
  const [isEndOfList, setIsEndOfList] = useState(false);

  const getPaginatedResults = () => {
    if (!openFixed || isEndOfList) {
      return;
    }
    setLoadingFixed(true);
    api
      .get(
        `${host()}${endpoints.imageListWithCVEFixed(
          cve.id,
          name,
          { pageNumber, pageSize: CVE_FIXEDIN_PAGE_SIZE },
          platform ? { Os: platform.Os, Arch: platform.Arch } : {}
        )}`,
        abortController.signal
      )
      .then((response) => {
        if (response.data && response.data.data) {
          const fixedTagsList = response.data.data.ImageListWithCVEFixed?.Results?.map((e) => e.Tag);
          setFixedInfo((previousState) => [...previousState, ...fixedTagsList]);
          setIsEndOfList(
            [...fixedInfo, ...fixedTagsList].length >= response.data.data.ImageListWithCVEFixed?.Page?.TotalCount
          );
        }
        setLoadingFixed(false);
      })
      .catch((e) => {
        console.error(e);
        setIsEndOfList(true);
        setLoadingFixed(false);
      });
  };

  useEffect(() => {
    getPaginatedResults();
    return () => {
      abortController.abort();
    };
  }, [openFixed, pageNumber]);

  useEffect(() => {
    setOpenCVE(expand);
  }, [expand]);

  const loadMore = () => {
    if (loadingFixed || isEndOfList) return;
    setPageNumber((pageNumber) => pageNumber + 1);
  };

  const renderFixedVer = () => {
    if (!isEmpty(fixedInfo)) {
      return fixedInfo.map((tag, index) => {
        return (
          <Link key={index} to={`/image/${encodeURIComponent(name)}/tag/${tag}`} className={classes.link}>
            {tag}
          </Link>
        );
      });
    } else {
      return 'Not fixed';
    }
  };

  const renderLoadMore = () => {
    return (
      !isEndOfList && (
        <Typography
          sx={{
            color: '#3366CC',
            cursor: 'pointer',
            fontSize: '1rem',
            letterSpacing: '0.009375rem',
            paddingRight: '1rem',
            textDecorationLine: 'underline'
          }}
          onClick={loadMore}
          component="div"
        >
          Load more
        </Typography>
      )
    );
  };

  return (
    <Card className={classes.card} raised>
      <CardContent className={classes.content}>
        <Stack direction="row" spacing="1.25rem">
          {!openCVE ? (
            <KeyboardArrowRight className={classes.dropdownCVE} onClick={() => setOpenCVE(!openCVE)} />
          ) : (
            <KeyboardArrowDown className={classes.dropdownCVE} onClick={() => setOpenCVE(!openCVE)} />
          )}
          <Typography variant="body1" align="left" className={classes.cveId}>
            {cve.id}
          </Typography>
          <VulnerabilityChipCheck vulnerabilitySeverity={cve.severity} />
        </Stack>
        <Collapse in={openCVE} timeout="auto" unmountOnExit>
          <Typography variant="body1" align="left" className={classes.cveSummary}>
            {cve.title}
          </Typography>
          <Divider className={classes.vulnerabilityCardDivider} />
          <Stack className={classes.dropdown} onClick={() => setOpenFixed(!openFixed)}>
            {!openFixed ? (
              <KeyboardArrowRight className={classes.dropdownText} />
            ) : (
              <KeyboardArrowDown className={classes.dropdownText} />
            )}
            <Typography className={classes.dropdownText}>Fixed in</Typography>
          </Stack>
          <Collapse in={openFixed} timeout="auto" unmountOnExit>
            <Box sx={{ width: '100%', padding: '0.5rem 0' }}>
              {loadingFixed ? (
                'Loading...'
              ) : (
                <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
                  {renderFixedVer()}
                  {renderLoadMore()}
                </Stack>
              )}
            </Box>
          </Collapse>
          <Stack className={classes.dropdown} onClick={() => setOpenDesc(!openDesc)}>
            {!openDesc ? (
              <KeyboardArrowRight className={classes.dropdownText} />
            ) : (
              <KeyboardArrowDown className={classes.dropdownText} />
            )}
            <Typography className={classes.dropdownText}>Description</Typography>
          </Stack>
          <Collapse in={openDesc} timeout="auto" unmountOnExit>
            <Box sx={{ padding: '0.5rem 0' }}>
              <Typography variant="body2" align="left" sx={{ color: '#0F2139', fontSize: '1rem' }}>
                {cve.description}
              </Typography>
            </Box>
          </Collapse>
        </Collapse>
      </CardContent>
    </Card>
  );
}

export default VulnerabilitiyCard;
