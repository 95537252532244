import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { Stack, Tooltip } from '@mui/material';

const criticalColor = '#ff5c74';
const criticalBorderColor = '#f9546d';

const highColor = '#ff6840';
const highBorderColor = '#ee6b49';

const mediumColor = '#ffa052';
const mediumBorderColor = '#f19d5b';

const lowColor = '#f9f486';
const lowBorderColor = '#f0ed94';

const unknownColor = '#f2ffdd';
const unknownBorderColor = '#e9f4d7';

const fontSize = '0.75rem';

const useStyles = makeStyles((theme) => ({
  cveCountCard: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    color: theme.palette.primary.main,
    fontSize: fontSize,
    fontWeight: '600',
    borderRadius: '3px',
    marginBottom: '0'
  },
  severityList: {
    fontSize: fontSize,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '0.5em'
  },
  criticalSeverity: {
    backgroundColor: criticalColor,
    border: '1px solid ' + criticalBorderColor
  },
  highSeverity: {
    backgroundColor: highColor,
    border: '1px solid ' + highBorderColor
  },
  mediumSeverity: {
    backgroundColor: mediumColor,
    border: '1px solid ' + mediumBorderColor
  },
  lowSeverity: {
    backgroundColor: lowColor,
    border: '1px solid ' + lowBorderColor
  },
  unknownSeverity: {
    backgroundColor: unknownColor,
    border: '1px solid ' + unknownBorderColor
  }
}));

function VulnerabilitiyCountCard(props) {
  const classes = useStyles();
  const { total, critical, high, medium, low, unknown } = props;

  return (
    <Stack direction="row" spacing="0.5em">
      <div className={[classes.cveCountCard].join(' ')}>Total {total}</div>
      <div className={classes.severityList}>
        <Tooltip title="Critical">
          <div className={[classes.cveCountCard, classes.criticalSeverity].join(' ')}>C {critical}</div>
        </Tooltip>
        <Tooltip title="High">
          <div className={[classes.cveCountCard, classes.highSeverity].join(' ')}>H {high}</div>
        </Tooltip>
        <Tooltip title="Medium">
          <div className={[classes.cveCountCard, classes.mediumSeverity].join(' ')}>M {medium}</div>
        </Tooltip>
        <Tooltip title="Low">
          <div className={[classes.cveCountCard, classes.lowSeverity].join(' ')}>L {low}</div>
        </Tooltip>
        <Tooltip title="Unknown">
          <div className={[classes.cveCountCard, classes.unknownSeverity].join(' ')}>U {unknown}</div>
        </Tooltip>
      </div>
    </Stack>
  );
}

export default VulnerabilitiyCountCard;
