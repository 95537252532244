import React from 'react';
import { Chip, Tooltip } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as failedScanBug } from '../assets/failedScan.svg';
import { createSvgIcon } from '@mui/material/utils';
import SignatureTooltip from 'components/Shared/SignatureTooltip';

const FilledBugIcon = createSvgIcon(
  <path d="M17.0293 5.13093V6.1543H18.3828L21.2414 3.24068L22.2621 4.27812L19.5552 7.03876L19.5879 7.12668C20.1841 8.73695 20.4862 10.4449 20.4793 12.1662C20.4793 12.5064 20.4678 12.8466 20.4448 13.186L20.4397 13.2634H24V14.7334H20.2569L20.2466 14.7932C19.9431 16.4882 19.3517 18.0338 18.5466 19.335L18.4862 19.4335L21.9276 22.9608L20.9052 24L17.6121 20.6239L17.5138 20.7365C16.0259 22.4333 14.0983 23.4514 11.9983 23.4514C9.86724 23.4514 7.91207 22.4016 6.41552 20.6573L6.31552 20.5413L3.08966 23.833L2.06897 22.792L5.45345 19.3403L5.39483 19.2436C4.61897 17.9618 4.04655 16.4478 3.75 14.7932L3.73966 14.7334H0V13.2634H3.55862L3.55345 13.1843C3.53103 12.8502 3.51897 12.509 3.51897 12.1644C3.51202 10.4654 3.80581 8.77905 4.38621 7.18646L4.41897 7.1003L1.64138 4.2535L2.66379 3.21606L5.53103 6.1543H6.96724V5.13093C6.96724 3.77012 7.49729 2.46505 8.4408 1.50281C9.3843 0.540578 10.664 0 11.9983 0C13.3326 0 14.6123 0.540578 15.5558 1.50281C16.4993 2.46505 17.0293 3.77012 17.0293 5.13093Z" />,
  'FilledBug'
);
const OutlinedBugIcon = createSvgIcon(
  <path d="M15.3155 5.75V4.877C15.3155 3.71615 14.8544 2.60284 14.0335 1.78199C13.2127 0.961147 12.0994 0.5 10.9385 0.5C9.77765 0.5 8.66434 0.961147 7.84349 1.78199C7.02265 2.60284 6.5615 3.71615 6.5615 4.877V5.75H5.312L2.8175 3.2435L1.928 4.1285L4.3445 6.557L4.316 6.6305C3.81106 7.98908 3.55546 9.42763 3.5615 10.877C3.5615 11.171 3.572 11.462 3.5915 11.747L3.596 11.8145H0.5V13.0685H3.7535L3.7625 13.1195C4.0205 14.531 4.5185 15.8225 5.1935 16.916L5.2445 16.9985L2.3 19.943L3.188 20.831L5.9945 18.023L6.0815 18.122C7.3835 19.61 9.0845 20.5055 10.9385 20.5055C12.7655 20.5055 14.4425 19.637 15.737 18.1895L15.8225 18.0935L18.6875 20.9735L19.577 20.087L16.583 17.078L16.6355 16.994C17.336 15.884 17.8505 14.5655 18.1145 13.1195L18.1235 13.0685H21.38V11.8145H18.2825L18.287 11.7485C18.307 11.4589 18.317 11.1688 18.317 10.8785C18.323 9.41018 18.0602 7.95316 17.5415 6.5795L17.513 6.5045L19.868 4.1495L18.98 3.2645L16.493 5.75H15.3155ZM7.8155 5.75V4.877C7.8155 4.04873 8.14453 3.25438 8.73021 2.66871C9.31588 2.08303 10.1102 1.754 10.9385 1.754C11.7668 1.754 12.5611 2.08303 13.1468 2.66871C13.7325 3.25438 14.0615 4.04873 14.0615 4.877V5.75H7.817H7.8155ZM16.361 7.0055L16.391 7.085C16.8155 8.2145 17.0615 9.5 17.0615 10.877C17.0615 13.292 16.307 15.428 15.1505 16.9325C13.9955 18.434 12.494 19.25 10.9385 19.25C9.3845 19.25 7.883 18.434 6.728 16.9325C5.57 15.428 4.8155 13.292 4.8155 10.877C4.8155 9.5 5.0615 8.2145 5.4875 7.085L5.5175 7.0055H16.361Z" />,
  'OutlinedBug'
);
const UnverifiedShieldIcon = createSvgIcon(
  <path d="M12.4837 2C13.6167 2 19.5627 4.041 20.3487 4.828C21.0047 5.484 20.9947 6.014 20.9487 8.557C20.9307 9.575 20.9057 10.962 20.9057 12.879C20.9057 19.761 13.0357 22.223 12.7007 22.324C12.6297 22.346 12.5567 22.356 12.4837 22.356C12.4107 22.356 12.3377 22.346 12.2667 22.324C11.9317 22.223 4.06165 19.761 4.06165 12.879C4.06165 10.959 4.03665 9.572 4.01865 8.554C4.01044 8.10043 4.00337 7.71095 4.00104 7.37341L4.00073 6.9925C4.00922 5.74112 4.1264 5.32 4.61765 4.828C5.40465 4.041 11.3507 2 12.4837 2ZM12.4837 3.5C11.6357 3.5 6.28465 5.384 5.66765 5.899C5.54931 6.018 5.50535 6.19514 5.49972 6.89808L5.49926 7.16877C5.50045 7.51182 5.50742 7.95335 5.51765 8.526C5.53665 9.552 5.56165 10.947 5.56165 12.879C5.56165 18.08 11.2837 20.389 12.4827 20.814C13.6807 20.387 19.4057 18.065 19.4057 12.879C19.4057 10.949 19.4307 9.555 19.4487 8.529C19.4592 7.95581 19.4663 7.51389 19.4674 7.17033L19.4668 6.89918C19.4605 6.19482 19.4138 6.01519 19.2877 5.889C18.6817 5.384 13.3317 3.5 12.4837 3.5ZM11.1346 9.5372L12.4837 10.887L13.8328 9.5372C14.1258 9.2442 14.5998 9.2442 14.8928 9.5372C15.1858 9.8302 15.1858 10.3042 14.8928 10.5972L13.5437 11.947L14.8926 13.2952C15.1856 13.5882 15.1856 14.0622 14.8926 14.3552C14.7466 14.5022 14.5546 14.5752 14.3626 14.5752C14.1706 14.5752 13.9786 14.5022 13.8326 14.3552L12.4837 13.007L11.1348 14.3552C10.9888 14.5022 10.7968 14.5752 10.6048 14.5752C10.4128 14.5752 10.2208 14.5022 10.0748 14.3552C9.78175 14.0622 9.78175 13.5882 10.0748 13.2952L11.4237 11.947L10.0746 10.5972C9.78155 10.3042 9.78155 9.8302 10.0746 9.5372C10.3676 9.2442 10.8416 9.2442 11.1346 9.5372Z" />,
  'UnverifiedShield'
);
const VerifiedShieldIcon = createSvgIcon(
  <path d="M12.4836 2C13.6166 2 19.5616 4.041 20.3486 4.828C21.0046 5.484 20.9946 6.014 20.9486 8.554C20.9306 9.572 20.9056 10.959 20.9056 12.879C20.9056 19.761 13.0356 22.223 12.7006 22.324C12.6296 22.346 12.5566 22.356 12.4836 22.356C12.4106 22.356 12.3376 22.346 12.2666 22.324C11.9316 22.223 4.06162 19.761 4.06162 12.879C4.06162 10.962 4.03662 9.575 4.01862 8.557C4.01041 8.10289 4.00334 7.71298 4.00102 7.37507L4.00073 6.99377C4.00931 5.74113 4.12687 5.32 4.61962 4.828C5.40462 4.041 11.3496 2 12.4836 2ZM12.4836 3.5C11.6356 3.5 6.28562 5.384 5.66862 5.899C5.48662 6.082 5.47962 6.4 5.51862 8.529C5.53662 9.555 5.56162 10.949 5.56162 12.879C5.56162 18.08 11.2836 20.389 12.4826 20.814C13.6806 20.387 19.4056 18.065 19.4056 12.879C19.4056 10.947 19.4306 9.552 19.4496 8.526C19.4876 6.399 19.4806 6.081 19.2876 5.889C18.6826 5.384 13.3316 3.5 12.4836 3.5ZM16.2051 9.3395C16.4981 9.6325 16.4981 10.1075 16.2051 10.4005L12.3071 14.2995C12.1951 14.4123 12.0505 14.4854 11.8952 14.5102L11.7771 14.5195C11.5781 14.5195 11.3871 14.4405 11.2461 14.2995L9.35412 12.4055C9.06212 12.1125 9.06212 11.6365 9.35512 11.3445C9.64712 11.0515 10.1231 11.0515 10.4161 11.3445L11.7771 12.7075L15.1451 9.3395C15.4381 9.0465 15.9121 9.0465 16.2051 9.3395Z" />,
  'VerifiedShield'
);

const NoneVulnerabilityIcon = ({ vulnerabilityStringTitle }) => {
  return (
    <Tooltip title={`${vulnerabilityStringTitle}`} placement="top">
      <OutlinedBugIcon
        sx={{
          color: '#43A047!important',
          padding: '0.2rem',
          background: '#E8F5E9',
          borderRadius: '1rem',
          height: '1.5rem',
          width: '1.6rem'
        }}
        data-testid="none-vulnerability-icon"
      />
    </Tooltip>
  );
};
const UnknownVulnerabilityIcon = ({ vulnerabilityStringTitle }) => {
  return (
    <Tooltip title={`${vulnerabilityStringTitle}`} placement="top">
      <OutlinedBugIcon
        sx={{
          color: '#52637A',
          padding: '0.2rem',
          background: '#ECEFF1',
          borderRadius: '1rem',
          height: '1.5rem',
          width: '1.6rem'
        }}
        data-testid="unknown-vulnerability-icon"
      />
    </Tooltip>
  );
};
const FailedScanIcon = () => {
  return (
    <Tooltip title="Failed to scan" placement="top">
      <SvgIcon
        component={failedScanBug}
        sx={{
          color: '#F6F7F9',
          padding: '0.2rem',
          background: '#848484',
          borderRadius: '1rem',
          height: '1.5rem',
          width: '1.6rem'
        }}
        data-testid="failed-vulnerability-icon"
      />
    </Tooltip>
  );
};
const LowVulnerabilityIcon = ({ vulnerabilityStringTitle }) => {
  return (
    <Tooltip title={`${vulnerabilityStringTitle}`} placement="top">
      <OutlinedBugIcon
        sx={{
          color: '#FB8C00',
          padding: '0.2rem',
          background: '#FFF3E0',
          borderRadius: '1rem',
          height: '1.5rem',
          width: '1.6rem'
        }}
        data-testid="low-vulnerability-icon"
      />
    </Tooltip>
  );
};
const MediumVulnerabilityIcon = ({ vulnerabilityStringTitle }) => {
  return (
    <Tooltip title={`${vulnerabilityStringTitle}`} placement="top">
      <FilledBugIcon
        sx={{
          color: '#FB8C00',
          padding: '0.2rem',
          background: '#FFF3E0',
          borderRadius: '1rem',
          height: '1.5rem',
          width: '1.6rem'
        }}
        data-testid="medium-vulnerability-icon"
      />
    </Tooltip>
  );
};
const HighVulnerabilityIcon = ({ vulnerabilityStringTitle }) => {
  return (
    <Tooltip title={`${vulnerabilityStringTitle}`} placement="top">
      <OutlinedBugIcon
        sx={{
          color: '#E53935',
          padding: '0.2rem',
          background: '#FEEBEE',
          borderRadius: '1rem',
          height: '1.5rem',
          width: '1.6rem'
        }}
        data-testid="high-vulnerability-icon"
      />
    </Tooltip>
  );
};
const CriticalVulnerabilityIcon = ({ vulnerabilityStringTitle }) => {
  return (
    <Tooltip title={`${vulnerabilityStringTitle}`} placement="top">
      <FilledBugIcon
        sx={{
          color: '#E53935',
          padding: '0.2rem',
          background: '#FEEBEE',
          borderRadius: '1rem',
          height: '1.5rem',
          width: '1.6rem'
        }}
        data-testid="critical-vulnerability-icon"
      />
    </Tooltip>
  );
};
const NoneVulnerabilityChip = () => {
  return (
    <Chip
      label="None"
      sx={{ backgroundColor: '#E8F5E9', color: '#388E3C', fontSize: '0.8125rem' }}
      variant="filled"
      icon={<OutlinedBugIcon sx={{ color: '#388E3C!important' }} />}
      data-testid="none-vulnerability-chip"
    />
  );
};
const UnknownVulnerabilityChip = () => {
  return (
    <Chip
      label="Unknown"
      sx={{ backgroundColor: '#ECEFF1', color: '#52637A', fontSize: '0.8125rem' }}
      variant="filled"
      icon={<OutlinedBugIcon sx={{ color: '#52637A!important' }} />}
      data-testid="unknown-vulnerability-chip"
    />
  );
};
const FailedScanChip = () => {
  return (
    <Chip
      label="Failed to scan"
      sx={{ backgroundColor: '#848484', color: '#F6F7F9', fontSize: '0.8125rem' }}
      variant="filled"
      icon={<SvgIcon component={failedScanBug} sx={{ color: '#F6F7F9!important' }} />}
      data-testid="failed-vulnerability-chip"
    />
  );
};
const LowVulnerabilityChip = () => {
  return (
    <Chip
      label="Low"
      sx={{ backgroundColor: '#FFF3E0', color: '#FB8C00', fontSize: '0.8125rem' }}
      variant="filled"
      icon={<OutlinedBugIcon sx={{ color: '#FB8C00!important' }} />}
      data-testid="low-vulnerability-chip"
    />
  );
};
const MediumVulnerabilityChip = () => {
  return (
    <Chip
      label="Medium"
      sx={{ backgroundColor: '#FFF3E0', color: '#FB8C00', fontSize: '0.8125rem' }}
      variant="filled"
      icon={<FilledBugIcon sx={{ color: '#FB8C00!important' }} />}
      data-testid="medium-vulnerability-chip"
    />
  );
};
const HighVulnerabilityChip = () => {
  return (
    <Chip
      label="High"
      sx={{ backgroundColor: '#FEEBEE', color: '#E53935', fontSize: '0.8125rem' }}
      variant="filled"
      icon={<OutlinedBugIcon sx={{ color: '#E53935!important' }} />}
      data-testid="high-vulnerability-chip"
    />
  );
};
const CriticalVulnerabilityChip = () => {
  return (
    <Chip
      label="Critical"
      sx={{ backgroundColor: '#FEEBEE', color: '#E53935', fontSize: '0.8125rem' }}
      variant="filled"
      icon={<FilledBugIcon sx={{ color: '#E53935!important' }} />}
      data-testid="critical-vulnerability-chip"
    />
  );
};

const UnverifiedSignatureIcon = ({ signatureInfo }) => {
  return (
    <Tooltip title={<SignatureTooltip isSigned={false} signatureInfo={signatureInfo} />} placement="top">
      <UnverifiedShieldIcon
        sx={{
          color: '#E53935',
          padding: '0.2rem',
          background: '#FEEBEE',
          borderRadius: '1rem',
          height: '1.5rem',
          width: '1.6rem'
        }}
        data-testid="unverified-icon"
      />
    </Tooltip>
  );
};
const VerifiedSignatureIcon = ({ signatureInfo }) => {
  return (
    <Tooltip title={<SignatureTooltip isSigned={true} signatureInfo={signatureInfo} />} placement="top">
      <VerifiedShieldIcon
        viewBox="0 0 24 24"
        sx={{
          color: '#43A047',
          alignSelf: 'center',
          padding: '0.2rem',
          background: '#E8F5E9',
          borderRadius: '1rem',
          height: '1.5rem',
          width: '1.6rem'
        }}
        data-testid="verified-icon"
      />
    </Tooltip>
  );
};

const UnverifiedSignatureChip = () => {
  return (
    <Chip
      label="Unverified Signature"
      sx={{ backgroundColor: '#FEEBEE', color: '#E53935', fontSize: '0.8125rem' }}
      variant="filled"
      onDelete={() => {
        return;
      }}
      deleteIcon={<UnverifiedShieldIcon sx={{ color: '#E53935!important' }} />}
    />
  );
};
const VerifiedSignatureChip = () => {
  return (
    <Chip
      label="Verified Signature"
      sx={{ backgroundColor: '#E8F5E9', color: '#388E3C', fontSize: '0.8125rem' }}
      variant="filled"
      onDelete={() => {
        return;
      }}
      deleteIcon={<VerifiedShieldIcon sx={{ color: '#388E3C!important' }} />}
    />
  );
};

export {
  NoneVulnerabilityIcon,
  UnknownVulnerabilityIcon,
  LowVulnerabilityIcon,
  MediumVulnerabilityIcon,
  HighVulnerabilityIcon,
  CriticalVulnerabilityIcon,
  NoneVulnerabilityChip,
  UnknownVulnerabilityChip,
  LowVulnerabilityChip,
  MediumVulnerabilityChip,
  HighVulnerabilityChip,
  CriticalVulnerabilityChip,
  UnverifiedSignatureIcon,
  VerifiedSignatureIcon,
  UnverifiedSignatureChip,
  VerifiedSignatureChip,
  FailedScanIcon,
  FailedScanChip
};
