import React from 'react';
import {
  NoneVulnerabilityIcon,
  LowVulnerabilityIcon,
  MediumVulnerabilityIcon,
  HighVulnerabilityIcon,
  CriticalVulnerabilityIcon,
  NoneVulnerabilityChip,
  LowVulnerabilityChip,
  MediumVulnerabilityChip,
  HighVulnerabilityChip,
  CriticalVulnerabilityChip,
  UnverifiedSignatureIcon,
  VerifiedSignatureIcon,
  UnverifiedSignatureChip,
  VerifiedSignatureChip,
  UnknownVulnerabilityIcon,
  UnknownVulnerabilityChip,
  FailedScanIcon,
  FailedScanChip
} from './vulnerabilityAndSignatureComponents';

const VulnerabilityIconCheck = ({ vulnerabilitySeverity }) => {
  let result;
  let vulnerabilityStringTitle = '';
  if (vulnerabilitySeverity) {
    vulnerabilityStringTitle = vulnerabilitySeverity.charAt(0) + vulnerabilitySeverity.substring(1).toLowerCase();
  }
  switch (vulnerabilitySeverity) {
    case 'NONE':
      result = <NoneVulnerabilityIcon vulnerabilityStringTitle={'No'} />;
      break;
    case 'LOW':
      result = <LowVulnerabilityIcon vulnerabilityStringTitle={vulnerabilityStringTitle} />;
      break;
    case 'MEDIUM':
      result = <MediumVulnerabilityIcon vulnerabilityStringTitle={vulnerabilityStringTitle} />;
      break;
    case 'HIGH':
      result = <HighVulnerabilityIcon vulnerabilityStringTitle={vulnerabilityStringTitle} />;
      break;
    case 'CRITICAL':
      result = <CriticalVulnerabilityIcon vulnerabilityStringTitle={vulnerabilityStringTitle} />;
      break;
    case 'UNKNOWN':
      result = <UnknownVulnerabilityIcon vulnerabilityStringTitle={vulnerabilityStringTitle} />;
      break;
    case '':
      result = <FailedScanIcon />;
      break;
    default:
      result = <></>;
  }
  return result;
};

const VulnerabilityChipCheck = ({ vulnerabilitySeverity }) => {
  let result;
  switch (vulnerabilitySeverity) {
    case 'NONE':
      result = <NoneVulnerabilityChip />;
      break;
    case 'LOW':
      result = <LowVulnerabilityChip />;
      break;
    case 'MEDIUM':
      result = <MediumVulnerabilityChip />;
      break;
    case 'HIGH':
      result = <HighVulnerabilityChip />;
      break;
    case 'CRITICAL':
      result = <CriticalVulnerabilityChip />;
      break;
    case 'UNKNOWN':
      result = <UnknownVulnerabilityChip />;
      break;
    case '':
      result = <FailedScanChip />;
      break;
    default:
      result = <></>;
  }
  return result;
};

const SignatureIconCheck = ({ isSigned, signatureInfo }) => {
  if (isSigned) {
    return <VerifiedSignatureIcon signatureInfo={signatureInfo} />;
  } else {
    return <UnverifiedSignatureIcon signatureInfo={signatureInfo} />;
  }
};

const SignatureChipCheck = ({ isSigned }) => {
  if (isSigned) {
    return <VerifiedSignatureChip />;
  } else {
    return <UnverifiedSignatureChip />;
  }
};

export { VulnerabilityIconCheck, VulnerabilityChipCheck, SignatureIconCheck, SignatureChipCheck };
